<template>
  <b-row>
    <b-col>
      <ag-grid-table
        :configUrl="historicoLineasProductivasConfig"
        :dataUrl="historicoLineasProductivasData"
        @selectionChanged="selectionChanged"
        rowSelection="multiple"
        :getRowNodeId="getRowNodeId"
        @gridReady="gridReady"
        :helper="true"
      >

      <template v-slot:actions>

                <b-button-toolbar
            aria-label="Toolbar with button groups and input groups"
            justify
          >
            <b-button-group style="margin-bottom: 1rem">
              <b-button
                size="sm"
                @click="selectAll()"
                variant="primary"
                >{{ $t('Select All') }}</b-button
              >
              <b-button
                size="sm"
                @click="clearSelection()"
                variant="secondary"
                >{{ $t('Clear Selection') }}</b-button
              >


            </b-button-group>

            <div>
                Registros seleccionados: {{ selected_lineas.length }}
            </div>

          </b-button-toolbar>


              </template>
      </ag-grid-table>

    </b-col>
  </b-row>
</template>
   
   <script>
import { BRow, BCol, BFormGroup, BButton, BFormSelect } from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import useApiServices from "@/services/useApiServices.js";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";

export default {
  props: {
  },
  components: {
    BRow,
    BCol,
    BrunaForm,
    AgGridTable
  },


  methods: {

    clearSelection()
    {
      this.gridApi.deselectAll()
    },

    selectAll()
    {
      console.log("select all")

      this.gridApi.forEachNode((rowNode, index) =>
      {
        console.log(rowNode)
        rowNode.setSelected(true)
      })
    },


    gridReady(gridApi, gridColumnApi, params)
    {
      this.gridApi = gridApi
      console.log("gridReady", { params })
    },


    selectionChanged(rows)
    {

      this.selected_lineas = rows

      this.$emit('selectedLineasChanged', rows);


    },



    getData()
    {
      return this.selected_lineas.map(item => item.id);

    },


    validate()
    {
      return this.selected_lineas.length > 0
    }


  },



  data()
  {
    return {

      gridApi: null,

      selected_lineas: [],

      historicoLineasProductivasConfig: useApiServices.nuevaPlanificacion.lineas.config,
      historicoLineasProductivasData: useApiServices.nuevaPlanificacion.lineas.data,


      getRowNodeId: (params) =>
      {
        return params.id;
      },


    };
  },
};
   </script>
   