<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          color="#b9cde5"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Crear Planificación"
          back-button-text="Atras"
          next-button-text="Siguiente"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <tab-content title="General" :before-change="beforeGeneralTabSwitch">
            <general-tab ref="generalTabRef"></general-tab>
          </tab-content>

          <tab-content title="Demanda" :before-change="beforeDemandaTabSwitch">
            <demanda-tab ref="demandaTabRef"></demanda-tab>
          </tab-content>

          <tab-content title="Líneas Productivas" :before-change="beforeLineasProductivasTabSwitch">
            <lineas-productivas-tab ref="lineasProductivasTabRef" @selectedLineasChanged="selectedLineasChanged"></lineas-productivas-tab>
          </tab-content>

          <tab-content title="Días Productivos" :before-change="beforeDiasProductivosTabSwitch">
            <dias-productivos-tab ref="diasProductivosTabRef" :selectedLineas="selectedLineas"></dias-productivos-tab>
          </tab-content>

          <!-- resumen -->
          <tab-content title="Resumen">
            <resume-tab
              :resume=resume
            >
            </resume-tab>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import
{
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";


import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";


import GeneralTab from './form-wizard-tabs/GeneralTab.vue';
import DemandaTab from './form-wizard-tabs/DemandaTab.vue';
import LineasProductivasTab from './form-wizard-tabs/LineasProductivasTab.vue';
import DiasProductivosTab from './form-wizard-tabs/DiasProductivosTab.vue';
import ResumeTab from './form-wizard-tabs/ResumeTab';

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    AgGridTable,
    ToastificationContent,
    BFormTags,

    GeneralTab,
    DemandaTab,
    LineasProductivasTab,
    DiasProductivosTab,
    ResumeTab
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data()
  {
    return {

      selectedLineas: [],
      resume: [],

    };
  },

  methods: {



    beforeGeneralTabSwitch()
    {
      return this.$refs['generalTabRef'].validate()

    },

    beforeStockTabSwitch()
    {
      return true;
    },
    beforeDemandaTabSwitch()
    {
      return this.$refs['demandaTabRef'].validate()

    },
    beforeLineasProductivasTabSwitch()
    {
      return this.$refs['lineasProductivasTabRef'].validate()
    },

    beforeDiasProductivosTabSwitch()
    {
      this.resume = this.resumeData();
      return this.$refs['diasProductivosTabRef'].validate()

    },


    beforeAjustesTabSwitch()
    {

      return true;
    },


    selectedLineasChanged(selectedLineas)
    {
      this.selectedLineas = selectedLineas
    },

    resumeData(){
      let general = this.$refs['generalTabRef'].getData()
      let demanda = this.$refs['demandaTabRef'].getData()
      let lines = this.$refs['lineasProductivasTabRef'].getData()
      let dias_productivos =  this.$refs['diasProductivosTabRef'].getRange()
      let inicio = dias_productivos.inicio.toLocaleDateString('es-PE');
      let fin = dias_productivos.fin.toLocaleDateString('es-PE');

      let select_dem_meta = this.$refs['demandaTabRef'].selected_demanda;
            
      let sku_meta = select_dem_meta.map((item) => {
        return item.meta_data.nueva_demanda;
      });

      sku_meta = sku_meta.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name
        ))
      )
      
      let sku_total_m2 = sku_meta.reduce((acumulador, actual) => acumulador + actual.total_m2, 0);

      // console.log('GENERAL => ',general);
        
      return [
        { name:'Modelo de Optimización', value: general.service_name, icon:'ClipboardIcon', size:6,variant:'light-secondary'},
        { name:'Función Objetivo Seleccionada', value: general.tipo_funcion_objetivo_name, icon:'ClipboardIcon', size:6,variant:'light-secondary'},
        { name:'Nº de Generaciones Seleccionadas', value: general.max_generations, icon:'ClipboardIcon', size:6,variant:'light-secondary'},
        { name:'Nº de Demandas seleccionadas', value: demanda.length, icon:'ClipboardIcon',size:6,variant:'light-secondary'},
        { name:'Total M2 planificados', value: sku_total_m2.toFixed(0), icon:'ClipboardIcon', size:3,variant:'light-secondary'},
        { name:'Nº de líneas seleccionadas', value: lines.length, icon:'ClipboardIcon', size:3,variant:'light-secondary'},
        { name:'Fecha inicio planificación', value: inicio, icon:'ClipboardIcon',size:3,variant:'light-secondary'},
        { name:'Fecha fin planificación', value: fin, icon:'ClipboardIcon', size:3,variant:'light-secondary'},
      ]
    },

    formSubmitted()
    {
      let general = this.$refs['generalTabRef'].getData()

      let data = {
        'service_name': general.service_name,
        'general' : general,
        'demanda': this.$refs['demandaTabRef'].getData(),
        'lineas': this.$refs['lineasProductivasTabRef'].getData(),
        'dias_productivos': this.$refs['diasProductivosTabRef'].getData()


      }

      console.log(data)


      useApiServices.postModelsCreateInvocation(data)
        .then((response) =>
        {

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('Información'),
              icon: "UploadIcon",
              variant: "success",
              html: this.$t('La nueva optimización se ha creado con éxito.'),
            },
          });


        })
        .catch((error) =>
        {
          let message = error.response.data.message;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('Ocurrió un Error'),
              icon: "UploadIcon",
              variant: "danger",
              html: message,
            },
          });

        });


    },


  },
};
  </script>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.mb-5 {
  margin-bottom: 5%;
}
</style>